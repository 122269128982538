import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Hero from '../components/Hero/Hero';
import Section from '../components/Section/Section';

const Maintenance = (props) => (
      <Layout location={props.location}>
        <main className="navigation-spacer">
          <Helmet>
            <link
              rel="canonical"
              href="https://www.latitudefinancial.com.au/maintenance/"
            />
            <title>Maintenance | Latitude</title>
            <meta name="robots" content="noindex" />
          </Helmet>

          <Hero
            className=""
            backgroundPosition="center center"
            bg={require('../images/cable-hero.jpg')}
            h1={<React.Fragment>Maintenance</React.Fragment>}
           />

          <Section id="copy" className="pt-lg-5 pb-lg-5">
              <div className="row">
                <div className="col-12 col-lg-8 mx-lg-auto text-center">
                  <h3 className="d-none d-lg-block">We’re currently working to make our application form better, so it’s temporarily out of action. Check back again later and please accept our apologies for inconveniencing you while we undertake this important work.</h3>
                  <h5 className="d-block d-lg-none">We’re currently working to make our application form better, so it’s temporarily out of action. Check back again later and please accept our apologies for inconveniencing you while we undertake this important work.</h5>
                </div>
              </div>
          </Section>
        </main>
      </Layout>
);

export default Maintenance;
